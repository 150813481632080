













































import DetachableHeader from 'vue-detachable-header'
import 'vue-detachable-header/index.css'
import dimensions from 'library/assets/vars/dimensions'
import AccountHeader from '~/components/pages/customer/account-header'
import SiteLogo from './site-logo'
import CartIcon from './cart-icon'
import DesktopNav from './nav/desktop/nav'
import HeaderDropdown from './header-dropdown'
import SearchDropdown from './search-dropdown'
import ClickOutside from 'vue-click-outside'
import Banner from 'library/components/layout/header/site-banner'
import trapFocusMixin from 'library/mixins/trap-focus-mixin'

export default

	components: {
		DetachableHeader
		AccountHeader
		SiteLogo
		CartIcon
		DesktopNav
		HeaderDropdown
		SearchDropdown
		Banner
	}

	directives: {
		ClickOutside
	}

	mixins: [trapFocusMixin]

	data: ->
		searchOpen: false

	computed:
		showBanner: -> @$store.state.globals?.banner?.showBanner
		height: ->
			if @showBanner then return @headerH + @bannerH
			else return @headerH

		headerH: -> parseInt dimensions['header-h']
		bannerH: -> parseInt dimensions['banner-h']

	methods:
		openCart: ->
			@closeAllDropdowns()
			@$store.commit('cart/open')

		toggleSearch: ->
			return @closeSearch() if @searchOpen
			@closeAllDropdowns()
			@searchOpen = true
			@setTrap(@$refs.searchTrap)

		closeSearch: ->
			@searchOpen = false
			@releaseAllTraps()

		clickOutside: -> @closeAllDropdowns()

		closeAllDropdowns: -> @closeSearch()

	watch:
		$route: ->
			@closeAllDropdowns()
			@searchOpen = false
			@releaseAllTraps()

