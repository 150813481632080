import { render, staticRenderFns } from "./main-nav.vue?vue&type=template&id=749e2670&scoped=true&lang=pug&"
import script from "./main-nav.vue?vue&type=script&lang=coffee&"
export * from "./main-nav.vue?vue&type=script&lang=coffee&"
import style0 from "./main-nav.vue?vue&type=style&index=0&id=749e2670&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749e2670",
  null
  
)

export default component.exports