import { render, staticRenderFns } from "./site-banner.vue?vue&type=template&id=6d223bbb&scoped=true&lang=pug&"
import script from "./site-banner.vue?vue&type=script&lang=coffee&"
export * from "./site-banner.vue?vue&type=script&lang=coffee&"
import style0 from "./site-banner.vue?vue&type=style&index=0&id=6d223bbb&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d223bbb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Wysiwyg: require('/opt/build/repo/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default})
