




































import ChildMenuLinks from './child-menu-links'
import DropdownCta from './dropdown-cta'

export default

	components: { ChildMenuLinks, DropdownCta }

	props:
		item: Object

	data: ->
		active: 'none'

	mounted: ->

		# set the first one active
		@active = @item?.links?[0].id

	methods:
		activeClasses: (item) -> return 'active' if item.id == @active

		updateMenu: (menu) ->
			return if menu.id == @active
			@active = menu.id
			@$emit 'updateMenu'

			# focus the first link within the active menu
			@$nextTick =>
				@$refs.pane2.querySelectorAll('.child-item')[0].querySelector('a').focus()


