import { render, staticRenderFns } from "./child-menu-links.vue?vue&type=template&id=7f04d7aa&scoped=true&lang=pug&"
import script from "./child-menu-links.vue?vue&type=script&lang=coffee&"
export * from "./child-menu-links.vue?vue&type=script&lang=coffee&"
import style0 from "./child-menu-links.vue?vue&type=style&index=0&id=7f04d7aa&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f04d7aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default})
