











export default

	data: ->
		loaded: false

	computed:
		classes: -> [
			'site-banner' if @$store.state.globals?.banner?.showBanner
		]

